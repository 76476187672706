/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Heading, Link} from "@chakra-ui/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h3: "h3",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/featured/nothernwind-showcase.png",
    alt: "showcase image for web ui"
  })), "\n", React.createElement(_components.h3, null, "Summary"), "\n", React.createElement(Heading, {
    as: "div",
    size: "md"
  }, React.createElement(_components.p, null, "Create a brand, web experience and initial business lead funnel with automated email campaign. Design a brand identity, color, typography and mark that positioned itself at the desired market.")), "\n", React.createElement(_components.h3, null, "Results"), "\n", React.createElement(_components.p, null, "Design of a unique boutique agency site with clear experience defining services and funnel to lead capture."), "\n", React.createElement(_components.h4, null, "Deliverables"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "branding"), "\n", React.createElement(_components.li, null, "design direction"), "\n", React.createElement(_components.li, null, "user experience design"), "\n", React.createElement(_components.li, null, "web design"), "\n", React.createElement(_components.li, null, "development"), "\n", React.createElement(_components.li, null, "email design and automation"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
